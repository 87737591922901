import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';


const Product5 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-390 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-390.71243fd86ea26c70c460.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-390</title>
    <meta name="description" content={`Steel Shot, Size S-390 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-390.71243fd86ea26c70c460.jpg' />
  </Helmet>

    <Header heading="Steel Shot, Size S-390" />

    <div className="productdetail">

        <img src={product5} alt="Steel Shot, Size S-390" title="Steel Shot, Size S-390" width="auto" height="auto"/>
        <p>Steel shot size S-390 refers to a specific grade of abrasive particles used in industrial applications such as shot blasting and surface preparation. The S in S-390 denotes that it is a steel shot, while the number 390 indicates its nominal diameter in micrometers. In the case of S-390, the average diameter of the steel shot particles is around 0.390 millimeters. This size is chosen based on the desired impact energy and coverage for the particular application. S-390 steel shots are known for their durability, high impact resistance, and ability to efficiently remove contaminants, rust, and scale from various surfaces. These qualities make S-390 a popular choice in industries where consistent and effective surface treatment is crucial, such as in metal fabrication, automotive, and shipbuilding.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product5