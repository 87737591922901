import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './Header';
import "../Styles/mediaQuary.scss";
import "../Styles/products.scss";
import productsData from "../Data/product.json";
import sizeChart from "../Assets/Steel shots size chart.png";
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";

const productImg = [
  {
    "imglink": product1,
  },
  {
    "imglink": product2,
  },
  {
    "imglink": product3,
  },
  {
    "imglink": product4,
  },
  {
    "imglink": product5,
  },
  {
    "imglink": product6,
  },
  {
    "imglink": product7,
  },
  {
    "imglink": product8,
  },
  {
    "imglink": product9,
  },
  {
    "imglink": product10,
  },
  {
    "imglink": product11,
  }
]

function Product({ data, imglink }) {
  const { description, title, ChemicalComposition, Hardness, Microstructure } = data;
  const imgUrl = encodeURIComponent(imglink.toLowerCase().replace(/\s+/g, '-'));
  const desc = description ? encodeURIComponent(description.toLowerCase().replace(/\s+/g, '-')) : '';
  const chemComp = ChemicalComposition ? encodeURIComponent(ChemicalComposition.toLowerCase().replace(/\s+/g, '-')) : '';
  const micro = Microstructure ? encodeURIComponent(Microstructure.toLowerCase().replace(/\s+/g, '-')) : '';

  return (
    <Link
      to={{
        pathname: `/products/${encodeURIComponent(title.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-'))}`
      }}
    >


      <div className="product" data-cursorpointer={true}>
        <img style={{ width: "350px" }} src={imglink} alt={`Product: ${title} - ${description}`} title="`Product: ${title}`" loading="lazy" width="auto" height="auto" data-cursorpointer={true} />
        <h3 className="description" data-cursorpointer={true}>{title}</h3>
      </div>
    </Link>
  );
}

const Products = () => {
  const productsDataArray = Object.values(productsData);

  return (
    <div className='product-container'>
      <Helmet>
        <title>Rotocast Steel Shots: High-Performance Abrasive Media Made in India</title>
        <meta name="description" content="Clean blast power! 🇮🇳 Rotocast Steel Shots (S-930 to S-70) - premium steel, precise controls, advanced processing. Find your perfect size!" />
        <meta name="keywords" content="Rotocast steel shots, abrasive media, surface preparation, shot peening, cleaning and blasting, made in India, high-performancesteel shot sizes (S-930, S-780, S-660, S-460, S-390, S-280, S-230, S-170, S-110, S-70), clean refined steel, micro alloying, quality control, special processing line, international know-how, European equipment" />
        <link rel='canonical' href='https://steelshot.in/products' />
        <meta property="og:title" content="Leading Steel Shot Manufacturer in India" />
        <meta property="og:site_name" content="Rotocast" />
        <meta property="og:url" content='https://steelshot.in/products' />
        <meta property="og:description" content="High-quality abrasive media for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale" />
        <meta property="og:type" content="product" />
        <meta property="og:image" content="https://rotocastgroup.com/wp-content/uploads/2023/09/Types_of_Steel_Shot-01.jpg" />
      </Helmet>

      <Header heading={"Our Products"} />
      <div className="product-intro">
        <div className="content">
          <p>
            Rotocast Steel Shots represent a breakthrough in high-performance abrasive media manufacturing, proudly introduced for the first time in India. Crafted from meticulously refined steel with specialized micro-alloying techniques and stringent quality controls, these steel shot media ensure exceptional performance. Processed and packaged using state-of-the-art equipment sourced from Europe and guided by international expertise, our steel shots guarantee unrivaled quality and consistency. Explore our comprehensive size distribution chart, providing precise details on available options, empowering you to select the perfect solution tailored to your needs with confidence.
          </p>
        </div>
        <div className="image">
          <img src={sizeChart} alt="Steel Shot Size Distribution Table" title="Steel Shot Size Distribution Table" loading="lazy" width="auto" height="auto" />
        </div>
      </div>
      <div className="products">
        {productsDataArray.map((product, index) => (
          <Product key={index} data={product} imglink={productImg[index].imglink} />
        ))}
      </div>
    </div>
  );
}

export default Products;