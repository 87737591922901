import React from 'react';
import Header from './Header';
import '../Styles/aboutus.scss';
import '../Styles/mediaQuary.scss';

import aboutusImg from '../Assets/Ensuring_Safety_in_Steel_Shot_Handling_and_Storage.png';
import { Helmet } from 'react-helmet';

const Aboutus = () => {
    return (
        <div className='aboutus-container'>
            <Helmet>
                <title>Rotocast Steel Shot: Your Partner for Quality, Precision, & Performance</title>
                <meta name="description" content="Premium steel shot. Quality, innovation, satisfaction. Surface prep, shot peening & more. Contact us today!" />
                <meta name="keywords" content="Rotocast steel shot, steel shot manufacturer , surface preparation, shot peening, abrasive media, high-quality steel shot, precision manufacturing, custom solutions, timely delivery, environmental, responsibility, automotive industry, aerospace industry ,construction industry, metal fabrication, industrial applications, quality control, state-of-the-art facilities, customer, service, reliable steel shot, cost-effective solutions" />
                <link rel='canonical' href='https://steelshot.in/aboutus' />

                <meta property="og:title" content="Leading Steel Shot Manufacturer in India" />
                <meta property="og:site_name" content="Rotocast" />
                <meta property="og:url" content='https://steelshot.in/aboutus' />
                <meta property="og:description" content="High-quality abrasive media for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://rotocastgroup.com/wp-content/uploads/2023/09/Types_of_Steel_Shot-01.jpg" />

            </Helmet>
            <Header heading={'About Rotocast'} />
            <script type="application/ld+json">
                {`{
        "@context": "https://schema.org",
        "@type": "AboutPage",
        "name": "About Rotocast",
        "description": "Welcome to Rotocast – Your Trusted Partner in Steel Shot Manufacturing. At Rotocast, we take pride in being a leading provider of high-quality steel shots for various industrial applications. With a commitment to excellence and a focus on delivering top-notch products, we have established ourselves as a reliable name in the industry. Our journey began with a vision to provide cutting-edge solutions for surface preparation, shot peening, and other applications requiring abrasive materials. Over the years, we have evolved and expanded our capabilities to meet the diverse needs of our clients. What sets us apart is our dedication to quality, precision, and customer satisfaction. Our state-of-the-art manufacturing facilities and a team of skilled professionals enable us to produce steel shots that meet the highest industry standards. Key Features of Rotocast: Premium Quality Steel Shots, Advanced Manufacturing Technology, Customized Solutions, Timely Delivery, Environmental Responsibility. Whether you are in the automotive, aerospace, or construction industry, Rotocast is here to provide you with reliable and cost-effective steel shot solutions. We understand the importance of surface finishing in your processes, and we are committed to helping you achieve optimal results. Thank you for considering Rotocast as your partner in steel shot manufacturing. We look forward to serving your needs and contributing to the success of your projects. Best Regards, The Rotocast Team",
        "url": "https://steelshot.in/aboutus",
        "breadcrumb": "Home > About Us",
        "about": {
          "@type": "Organization",
          "name": "Rotocast",
          "description": "Rotocast is a leading provider of high-quality steel shots for various industrial applications. With a commitment to excellence and a focus on delivering top-notch products, we have established ourselves as a reliable name in the industry.",
          "url": "https://steelshot.in/",
          "foundingDate": "Year of founding",
          "founder": "Hari Krishan Mohta",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "591 Urla Industrial Complex, Urla",
            "addressLocality": "Raipur",
            "addressRegion": "(CG)",
            "postalCode": "492003",
            "addressCountry": "India"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Service",
            "telephone": "9201592832",
            "email": "enquiry@rotocastgroup.com",
            "availableLanguage": ["English", "Hindi"]
          },
          "logo": "https://rotocastgroup.com/wp-content/uploads/2023/12/rotocast-logo.png",
          "sameAs": [
            "https://www.facebook.com/rotocastgroup/",
            "https://www.linkedin.com/company/rotocast-industries-ltd-/",
            "https://www.instagram.com/rotocastgroup/"
          ]
        }
      }      
      `}
            </script>
            <div className='aboutus'>
                <div className='content'>
                    <p>
                        <h2>Welcome to Rotocast – Your Trusted Partner in Steel Shot Manufacturing.</h2>
                    </p>
                    <br />

                    <p>
                        At Rotocast, we take pride in being a leading provider of high-quality
                        steel shots for various industrial applications. With a commitment to
                        excellence and a focus on delivering top-notch products, we have
                        established ourselves as a reliable name in the industry.
                    </p>
                    <br />

                    <p>
                        Our journey began with a vision to provide cutting-edge solutions for
                        surface preparation, shot peening, and other applications requiring
                        abrasive materials. Over the years, we have evolved and expanded our
                        capabilities to meet the diverse needs of our clients.
                    </p>
                    <br />

                    <p>
                        What sets us apart is our dedication to quality, precision, and
                        customer satisfaction. Our state-of-the-art manufacturing facilities
                        and a team of skilled professionals enable us to produce steel shots
                        that meet the highest industry standards.
                    </p>
                    <br />
                    <p>
                        <h3>Key Features of Rotocast:</h3>
                        <ul>
                            <li>Premium Quality Steel Shots</li>
                            <li>Advanced Manufacturing Technology</li>
                            <li>Customized Solutions</li>
                            <li>Timely Delivery</li>
                            <li>Environmental Responsibility</li>
                        </ul>
                    </p>
                    <br />

                    <p>
                        Whether you are in the automotive, aerospace, or construction
                        industry, Rotocast is here to provide you with reliable and
                        cost-effective steel shot solutions. We understand the importance of
                        surface finishing in your processes, and we are committed to helping
                        you achieve optimal results.
                    </p>
                    <br />

                    <p>
                        Thank you for considering Rotocast as your partner in steel shot
                        manufacturing. We look forward to serving your needs and contributing
                        to the success of your projects.
                    </p>
                    <br />

                    <p>Best Regards,</p>
                    <p>The Rotocast Team</p>
                </div>
                <div className='img-container'>
                    <img src={aboutusImg}
                        alt='Ensuring Safety in Steel Shot Handling and Storage'
                        title="Steel Shot Manufacturing"
                        width="auto"
                        height="auto" /></div>
            </div>
        </div>
    );
};

export default Aboutus;
