import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';

const Product11 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-330 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-550.9a387824c3a6c4107b8e.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-330</title>
    <meta name="description" content={`Steel Shot, Size S-330 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-550.9a387824c3a6c4107b8e.jpg' />
  </Helmet>

    <Header heading="Steel Shot, Size S-330" />

    <div className="productdetail">

        <img src={product11} alt="Steel Shot, Size S-330" title="Steel Shot, Size S-330" width="auto" height="auto"/>
        <p>Steel shot size S-330 refers to a specific classification within the range of steel shots used for abrasive blasting processes. The “S” designation indicates that the shots are spherical in shape, while the number “330” denotes the approximate diameter of the individual steel particles in mesh size. In the case of S-330, the particles have a diameter of around 0.0394 inches (1.0 mm). This particular size is commonly employed for heavy-duty applications in abrasive blasting, where a robust and efficient removal of rust, scale, or other surface contaminants is essential. The S-330 steel shots exhibit high impact energy, ensuring effective surface preparation in industries such as shipbuilding, construction, and metal fabrication. The uniformity and hardness of S-330 steel shots contribute to their durability and reusability, making them a reliable choice for demanding blasting operations.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%
          Silicon 0.45-1.50%
          Manganese 0.35-1.20%
          Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C)
          Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product11