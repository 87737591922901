import React from 'react'


const SchemaMarkup = () => {
  return (
    <>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Revolutionize Surface Preparation with Top-Tier Steel Shots",
            "description": "Discover Unrivaled Quality and Performance for Blasting Applications in Various Industries",
            "url": "https://steelshot.in/",
            "breadcrumb": "Home",
            "mainEntity": {
              "@type": "Product",
              "name": "Steel Shots",
              "description": "Experience unparalleled surface preparation with our premium steel shots. Renowned for their exceptional abrasiveness, our steel shots guarantee optimal surface roughness, ensuring superior adhesion for coatings and finishes. Engineered for extended durability, these high-quality shots minimize downtime, maximizing productivity across diverse applications. Versatile and adaptable, our steel shots find their place in manufacturing, construction, automotive, and shipbuilding industries, offering consistent and reliable performance. Committed to environmental responsibility, our eco-friendly steel shots deliver top-notch results with minimal environmental impact. Elevate your projects with the precision and efficiency of our steel shots, setting new standards in blasting applications.",
              "url": "https://steelshot.in/products",
              "image": "https://rotocastgroup.com/wp-content/uploads/2024/01/S-70-03.jpg",
              "brand": {
                "@type": "Brand",
                "name": "Rotocast"
              }
            },
            "headline": "Revolutionize Surface Preparation with Top-Tier Steel Shots",
            "publisher": {
              "@type": "Organization",
              "name": "Rotocast Group",
              "url": "https://steelshot.in/",
              "logo": {
                "@type": "ImageObject",
                "url": "https://rotocastgroup.com/wp-content/uploads/2023/12/rotocast-logo.png"
              }
            }
          }
          `}
        </script>

     
    </>
  )
}

export default SchemaMarkup