import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';


const Product7 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-230 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-230.ed99453216ec2d7f373c.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-230</title>
    <meta name="description" content={`Steel Shot, Size S-230 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-230.ed99453216ec2d7f373c.jpg' />
  </Helmet>


    <Header heading="Steel Shot, Size S-230" />

    <div className="productdetail">

        <img src={product7} alt="Steel Shot, Size S-230" title="Steel Shot, Size S-230" width="auto" height="auto"/>
        <p>Steel shot size S-230 refers to a specific classification within the abrasive blasting industry, indicating the particle size of the steel shots used for surface cleaning and preparation. In this context, S-230 denotes a nominal diameter range between 0.6 and 0.7 millimeters. These steel shots are commonly employed in various applications, such as cleaning, peening, and descaling surfaces like metal, concrete, or other materials. The S-230 size is chosen based on its ability to provide effective and efficient results in removing rust, scale, and other contaminants from surfaces, leaving behind a clean and properly prepared substrate. The use of steel shots in abrasive blasting processes is integral to achieving desired surface finishes and enhancing the adhesion of coatings or paints. The S-230 size, with its specific particle dimensions, ensures a balance between material removal efficiency and surface texture, making it a versatile choice in industrial cleaning and surface treatment applications.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product7