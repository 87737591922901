import React from 'react';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import "../Styles/fullblog.scss";
import { Helmet } from 'react-helmet';
import IsBlog1 from './IsBlog1';
import IsBlog2 from './IsBlog2';
import IsBlog3 from './IsBlog3';
import IsBlog4 from './IsBlog4';
import IsBlog5 from './IsBlog5';
import IsBlog6 from './IsBlog6';
import IsBlog7 from './IsBlog7';
import IsBlog8 from './IsBlog8';
import IsBlog9 from './IsBlog9';
import IsBlog10 from './IsBlog10';

const FullBlog = () => {
  const location = useLocation();
  const { imglink, description, title, description2, title2, keyword } = location.state || {};
  const canonicalUrl = window.location.href;
  const isBlog1 = location.pathname === '/blogs/can-steel-shots-be-recycled'; // Corrected the path comparison
  const isBlog2 = location.pathname === '/blogs/steel-shots-for-automotive-surface-treatment'; // Corrected the path comparison
  const isBlog3 = location.pathname === '/blogs/how-is-the-quality-of-stainless-steel-shots-maintained'; // Corrected the path comparison
  const isBlog4 = location.pathname === '/blogs/what-grades-of-stainless-steel-are-used-for-making-stainless-steel-shots'; // Corrected the path comparison
  const isBlog5 = location.pathname === '/blogs/are-there-different-types-of-steel-shots'; // Corrected the path comparison
  const isBlog6 = location.pathname === '/blogs/what-is-shot-peening'; // Corrected the path comparison
  const isBlog7 = location.pathname === '/blogs/are-there-any-safety-considerations-when-using-steel-shots'; // Corrected the path comparison
  const isBlog8 = location.pathname === '/blogs/what-are-the-applications-of-stainless-steel-shots'; // Corrected the path comparison
  const isBlog9 = location.pathname === '/blogs/future-trends-in-steel-shot-technology'; // Corrected the path comparison
  const isBlog10 = location.pathname === '/blogs/innovations-in-steel-shot-manufacturing'; // Corrected the path comparison

  return (
    <div className='FullBlog-container'>
<helmet>
<link rel='canonical' href={canonicalUrl}/>
<meta property="og:url" content={canonicalUrl}/>

</helmet>
    
      
      {isBlog1 && 
      <div>
        <Header heading="Can Steel Shots Be Recycled?" />
        <IsBlog1/>
      </div>
      }
      {isBlog2 && 
        <div>
        <Header heading="Steel Shots for Automotive Surface Treatment" />
        <IsBlog2/>
        </div>
      }
      {isBlog3 && 
        <div>
        <Header heading="How is the quality of stainless steel shots maintained?" />
        <IsBlog3/>
        </div>
      }
      {isBlog4 && 
        <div>
        <Header heading="What grades of stainless steel are used for making stainless steel shots?" />
        <IsBlog4/>
        </div>
      }
      {isBlog5 && 
        <div>
        <Header heading="Are there different types of steel shots?" />
        <IsBlog5/>
        </div>
      }
      {isBlog6 && 
        <div>
        <Header heading="What is shot peening?" />
        <IsBlog6/>
        </div>
      }
      {isBlog7 && 
        <div>
        <Header heading="Are there any safety considerations when using steel shots?" />
        <IsBlog7/>
        </div>
      }
      {isBlog8 && 
        <div>
        <Header heading="What are the applications of stainless steel shots?" />
        <IsBlog8/>
        </div>
      }
      {isBlog9 && 
        <div>
        <Header heading="Future Trends in Steel Shot Technology" />
        <IsBlog9/>
        </div>
      }
      {isBlog10 && 
        <div>
        <Header heading="Innovations in Steel Shot Manufacturing" />
        <IsBlog10/>
        </div>
      }
    </div>
  );
}

export default FullBlog;
