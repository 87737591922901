import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  const footerLinks = (element) => {
    const allFooterLinks = document.querySelectorAll(".footerLinks");

    switch (element) {
      case 0:
        allFooterLinks.forEach((item, index) =>
          index === 0 ? (item.style.opacity = 1) : (item.style.opacity = 0.2)
        );
        break;
      case 1:
        allFooterLinks.forEach((item, index) =>
          index === 1 ? (item.style.opacity = 1) : (item.style.opacity = 0.2)
        );
        break;
      case 2:
        allFooterLinks.forEach((item, index) =>
          index === 2 ? (item.style.opacity = 1) : (item.style.opacity = 0.2)
        );
        break;
      case 3:
        allFooterLinks.forEach((item, index) =>
          index === 3 ? (item.style.opacity = 1) : (item.style.opacity = 0.2)
        );
        break;
      case 4:
        allFooterLinks.forEach((item, index) =>
          index === 4 ? (item.style.opacity = 1) : (item.style.opacity = 0.2)
        );
        break;
      case 5:
        allFooterLinks.forEach((item, index) =>
          index === 5 ? (item.style.opacity = 1) : (item.style.opacity = 0.2)
        );
        break;

      default:
        allFooterLinks.forEach((item) => (item.style.opacity = 1));
        break;
    }
  };

  const linksBackToNormal = () => {
    const allFooterLinks = document.querySelectorAll(".footerLinks");
    allFooterLinks.forEach((item) => (item.style.opacity = 1));
  };

  return (
    <>
      <footer>
        <h4>
          #Transforming_Surfaces,
          <br />
          One Shot at a Time
        </h4>

        <aside onMouseLeave={linksBackToNormal}>
          <Link
            to="/"
            className="footerLinks"
            onMouseOver={() => footerLinks(0)}
            data-cursorpointermini={true}
          >
            Home
          </Link>
          <Link
            to="/aboutus"
            className="footerLinks"
            onMouseOver={() => footerLinks(1)}
            data-cursorpointermini={true}
          >
            About Us
          </Link>
          <Link
            to="/products"
            className="footerLinks"
            onMouseOver={() => footerLinks(2)}
            data-cursorpointermini={true}
          >
            Products
          </Link>
          <Link
            to="/applications"
            className="footerLinks"
            onMouseOver={() => footerLinks(3)}
            data-cursorpointermini={true}
          >
            Applications
          </Link>
          <Link
            to="/blogs"
            className="footerLinks"
            onMouseOver={() => footerLinks(4)}
            data-cursorpointermini={true}
          >
            Blogs
          </Link>
          <Link
            to="/contactus"
            className="footerLinks"
            onMouseOver={() => footerLinks(5)}
            data-cursorpointermini={true}
          >
            Contact Us
          </Link>
        </aside>

        <div>
        <h5>Address:</h5>
        <span>591 Urla Industrial Complex, Urla Raipur (CG), 492003</span>
          <div className="social-icons">
            <a href="https://www.facebook.com/youhttps://www.facebook.com/rotocastgroup/" >
              <FaFacebook data-cursorpointermini={true} />
            </a>
            <a href="https://www.linkedin.com/company/rotocast-industries-ltd-/" >
              <FaLinkedin data-cursorpointermini={true}/>
            </a>
            <a href="https://www.instagram.com/rotocastgroup/" >
              <FaInstagram data-cursorpointermini={true}/>
            </a>
            <a href="https://www.youtube.com/@Rotocast" >
              <FaYoutube data-cursorpointermini={true}/>
            </a>
          </div>
        </div>

        <div>
          <h5>Email</h5>
          <a data-cursorpointer={true} href="mailto: enquiry@rotocastgroup.com">enquiry@rotocastgroup.com</a>
          <h5>Phone</h5>
          <a data-cursorpointer={true} href="tel: +919201592832">+919201592832</a>
        </div>


        <p>© COPYRIGHT {new Date().getFullYear()} All Rights Reserved by <a  href="https://steelshot.in/"><b data-cursorpointer={true} >ROTOCAST</b></a> <br />
        <a data-cursorpointermini={true} style={{color: "gray", fontSize: 8}} href="/sitemap.xml">Sitemap</a>
        </p>

        
      </footer>
      <div className="footer"></div>
    </>
  );
};

export default Footer;