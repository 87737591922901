import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';

const Product8 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-170 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-170.02b9a974130bd2611201.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-170</title>
    <meta name="description" content={`Steel Shot, Size S-170 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-170.02b9a974130bd2611201.jpg' />
  </Helmet>

    <Header heading="Steel Shot, Size S-170" />

    <div className="productdetail">

        <img src={product8} alt="Steel Shot, Size S-170" title="Steel Shot, Size S-170" width="auto" height="auto"/>
        <p>Steel shot size S-170 refers to a specific classification within the range of steel shots used in various industrial applications. The S denotes its spherical shape, and the number 170 indicates its nominal diameter in mesh size. In this case, S-170 has an approximate diameter of 0.0058 inches or 0.1473 millimeters. This particular size is commonly employed in abrasive blasting processes, such as shot peening and surface preparation, where the goal is to clean, strengthen, or modify the surface of materials like metal, concrete, or composites. S-170 steel shots are known for their durability and hardness, making them effective for removing rust, scale, and contaminants from surfaces, while also imparting a desirable texture or finish. The selection of steel shot size is crucial in achieving specific surface treatment results, and S-170 finds its niche in applications requiring precision and consistent performance.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product8