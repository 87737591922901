import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import "../Styles/productDetail.scss";
import { Helmet } from 'react-helmet';
import Product1 from './Product1';
import Product2 from './Product2';
import Product3 from './Product3';
import Product4 from './Product4';
import Product5 from './Product5';
import Product6 from './Product6';
import Product7 from './Product7';
import Product8 from './Product8';
import Product9 from './Product9';
import Product10 from './Product10';
import Product11 from './Product11';


const ProductDetail = () => {
  const location = useLocation();
  const canonicalUrl = window.location.href;
  const queryParams = new URLSearchParams(location.search);
  const imglink = queryParams.get('imglink');
  const title = queryParams.get('title');
  const description = queryParams.get('description');
  const ChemicalComposition = queryParams.get('ChemicalComposition');
  const Hardness = queryParams.get('Hardness');
  const Microstructure = queryParams.get('Microstructure');


  const isProduct1 = location.pathname === '/products/steel-shot-size-s-930'; // Corrected the path comparison
  const isProduct2 = location.pathname === '/products/steel-shot-size-s-780'; // Corrected the path comparison
  const isProduct3 = location.pathname === '/products/steel-shot-size-s-660'; // Corrected the path comparison
  const isProduct4 = location.pathname === '/products/steel-shot-size-s-460'; // Corrected the path comparison
  const isProduct5 = location.pathname === '/products/steel-shot-size-s-390'; // Corrected the path comparison
  const isProduct6 = location.pathname === '/products/steel-shot-size-s-280'; // Corrected the path comparison
  const isProduct7 = location.pathname === '/products/steel-shot-size-s-230'; // Corrected the path comparison
  const isProduct8 = location.pathname === '/products/steel-shot-size-s-170'; // Corrected the path comparison
  const isProduct9 = location.pathname === '/products/steel-shot-size-s-110'; // Corrected the path comparison
  const isProduct10 = location.pathname === '/products/steel-shot-size-s-70'; // Corrected the path comparison
  const isProduct11 = location.pathname === '/products/steel-shot-size-s-330'; // Corrected the path comparison


  // Normalize image link to lowercase for comparison
  // const normalizedImgLink = imglink ? imglink.toLowerCase() : '';

  // Adjust the original URL to match the case of the image link
  // const originalImgUrl = imglink ? `https://rotocastgroup.com/wp-content/uploads/2024/01/${imglink.split('/').pop()}` : '';

  // Replace hyphens with spaces in each piece of content
  const formattedDescription = description ? description.replace(/-/g, ' ') : '';
  const formattedChemicalComposition = ChemicalComposition ? ChemicalComposition.replace(/-/g, ' ') : '';
  const formattedMicrostructure = Microstructure ? Microstructure.replace(/-/g, ' ') : '';

  return (
    <div className='productdetail-container'>
      <Helmet>
        <meta name="keywords" content="Rotocast steel shots, abrasive media, surface preparation, shot peening, cleaning and blasting, made in India, high-performancesteel shot sizes, clean refined steel, micro alloying, quality control, special processing line, international know-how, European equipment" />
        <link rel='canonical' href={canonicalUrl} />

        <meta property="og:title" content='Leading Steel Shot Manufacturer in India' />
        <meta property="og:site_name" content="Rotocast" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:description" content="Elevate your surface prep, shot peening, & blasting with Rotocast, India's leading steel shot manufacturer. Explore high-quality media, custom solutions, & expert support. Discover how we can empower your industry!" />
        <meta property="og:type" content="product" />
      </Helmet>

      {isProduct1 &&
        <Product1 />
      }
      {isProduct2 &&
        <Product2 />
      }
      {isProduct3 &&
        <Product3 />
      }
      {isProduct4 &&
        <Product4 />
      }
      {isProduct5 &&
        <Product5 />
      }
      {isProduct6 &&
        <Product6 />
      }
      {isProduct7 &&
        <Product7 />
      }
      {isProduct8 &&
        <Product8 />
      }
      {isProduct9 &&
        <Product9 />
      }
      {isProduct10 &&
        <Product10 />
      }
      {isProduct11 &&
        <Product11 />
      }
    </div>
  );
}

export default ProductDetail;
