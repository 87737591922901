import React from 'react'
import { Helmet } from 'react-helmet';
import Header from './Header'
import "../Styles/applications.scss"

function Application({ imglink, description, title, industries }) {
  return (
    <div className="application">
      <img src={imglink} alt={`Application: ${title} - ${description}`} />
      <div>
        <h2>{title}</h2>
        <p className="description">{description}</p>
        <p className="industries"> <h3>Industries: </h3>{industries}</p>
      </div>

    </div>
  );
}


const Applications = () => {
  const applicationData = [
    {
      imglink: "https://rotocastgroup.com/wp-content/uploads/2019/01/Surface-Preparation.jpg",
      title: "Surface Preparation:",
      description: "Our steel shots are extensively used in surface preparation processes such as cleaning, descaling, and derusting. The abrasive nature of our shots ensures efficient and consistent surface treatment for a wide range of materials.",
      industries: " Automotive, Aerospace, Shipbuilding, Foundries, Construction, and more.",
    },
    {
      imglink: "https://rotocastgroup.com/wp-content/uploads/2019/01/shot-Peening.jpg",
      title: "Shot Peening:",
      description: "In shot peening applications, our steel shots are employed to enhance the fatigue life and durability of metal components. The controlled shot peening process imparts compressive stress, improving resistance to cracking and fatigue failure.",
      industries: "Automotive, Aerospace, Manufacturing, and Precision Engineering.",
    },
    {
      imglink: "https://rotocastgroup.com/wp-content/uploads/2019/01/steel-shot-blasting.jpg",
      title: "Cleaning and Blasting:",
      description: "Our steel shots are ideal for cleaning and blasting applications, ensuring the removal of contaminants, paint, and coatings from various surfaces. The result is a clean and prepared surface ready for further treatment.",
      industries: " Manufacturing, Shipbuilding, Oil and Gas, and Metal Fabrication.",
    },
    {
      imglink: "https://rotocastgroup.com/wp-content/uploads/2023/12/steel-casting01.jpg",
      title: "Foundry Applications:",
      description: "Rotocast Steel Shots play a crucial role in foundry applications, contributing to the production of high-quality castings. The use of our shots aids in the removal of sand and other impurities, resulting in superior casting quality.",
      industries: " Foundries, Metal Casting.",
    },
    {
      imglink: "https://rotocastgroup.com/wp-content/uploads/2024/03/rotocast-manufacturing.jpg",
      title: " Steel Shot Blasting Machines",
      description: "Our steel shots are compatible with various shot blasting machines, ensuring optimal performance and efficiency in the blasting process. The uniform size and hardness of our shots contribute to consistent and reliable results.",
      industries: " Surface Treatment Facilities, Metal Processing Plants.",
    },
  ]

  return (
    <div className='application-container'>
      <Helmet>
        <title>Rotocast - Steel Shot Applications</title>
        <meta name="description" content="Unlock possibilities with Rotocast Steel Shots. Surface prep, shot peening, cleaning, blasting – explore industries we empower. Discover your solution!" />
        <meta name="keywords" content="steel shot applications, surface preparation, shot peening, cleaning and blasting, foundry, applications, steel shot blasting machines , automotive industry, aerospace industry, shipbuilding industry, foundries , construction industry, manufacturing industry metal fabrication, high-performance steel shot, consistent surface treatment, fatigue life improvement, contaminant removal, casting quality, shot blasting efficiency, reliable results" />
        <link rel='canonical' href='https://steelshot.in/applications' />

        <meta property="og:title" content="Leading Steel Shot Manufacturer in India" />
        <meta property="og:site_name" content="Rotocast" />
        <meta property="og:url" content='https://steelshot.in/applications' />
        <meta property="og:description" content="High-quality abrasive media for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://rotocastgroup.com/wp-content/uploads/2023/09/Types_of_Steel_Shot-01.jpg" />

        <script type="application/ld+json">
                {`{
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "headline": "Discover the Versatility of Our Steel Shot Products",
                  "description": "At Rotocast, we take pride in providing high-performance steel shots that find application across various industries. Explore the diverse applications where our steel shots excel.",
                  "mainEntity": {
                    "@type": "ItemList",
                    "itemListElement": [
                      {
                        "@type": "ListItem",
                        "position": 1,
                        "item": {
                          "@type": "Product",
                          "name": "Surface Preparation",
                          "description": "Our steel shots are extensively used in surface preparation processes such as cleaning, descaling, and derusting. The abrasive nature of our shots ensures efficient and consistent surface treatment for a wide range of materials.",
                          "applicationCategory": "Surface Preparation",
                          "industry": [
                            "Automotive",
                            "Aerospace",
                            "Shipbuilding",
                            "Foundries",
                            "Construction"
                          ]
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 2,
                        "item": {
                          "@type": "Product",
                          "name": "Shot Peening",
                          "description": "In shot peening applications, our steel shots are employed to enhance the fatigue life and durability of metal components. The controlled shot peening process imparts compressive stress, improving resistance to cracking and fatigue failure.",
                          "applicationCategory": "Shot Peening",
                          "industry": [
                            "Automotive",
                            "Aerospace",
                            "Manufacturing",
                            "Precision Engineering"
                          ]
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 3,
                        "item": {
                          "@type": "Product",
                          "name": "Cleaning and Blasting",
                          "description": "Our steel shots are ideal for cleaning and blasting applications, ensuring the removal of contaminants, paint, and coatings from various surfaces. The result is a clean and prepared surface ready for further treatment.",
                          "applicationCategory": "Cleaning and Blasting",
                          "industry": [
                            "Manufacturing",
                            "Shipbuilding",
                            "Oil and Gas",
                            "Metal Fabrication"
                          ]
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 4,
                        "item": {
                          "@type": "Product",
                          "name": "Foundry Applications",
                          "description": "Rotocast Steel Shots play a crucial role in foundry applications, contributing to the production of high-quality castings. The use of our shots aids in the removal of sand and other impurities, resulting in superior casting quality.",
                          "applicationCategory": "Foundry Applications",
                          "industry": [
                            "Foundries",
                            "Metal Casting"
                          ]
                        }
                      },
                      {
                        "@type": "ListItem",
                        "position": 5,
                        "item": {
                          "@type": "Product",
                          "name": "Steel Shot Blasting Machines",
                          "description": "Our steel shots are compatible with various shot blasting machines, ensuring optimal performance and efficiency in the blasting process. The uniform size and hardness of our shots contribute to consistent and reliable results.",
                          "applicationCategory": "Steel Shot Blasting Machines",
                          "industry": [
                            "Surface Treatment Facilities",
                            "Metal Processing Plants"
                          ]
                        }
                      }
                    ]
                  }
                }
                  `}
            </script>

            <script type="application/ld+json">
                {`{
                  "@context": "https://schema.org",
                  "@type": "Product",
                  "name": "Steel Shots",
                  "description": "Rotocast provides high-performance steel shots engineered to meet the demanding needs of surface preparation, shot peening, and beyond.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Rotocast"
                  },
                  "applicationCategory": [
                    "Surface Preparation",
                    "Shot Peening",
                    "Cleaning and Blasting",
                    "Foundry Applications",
                    "Steel Shot Blasting Machines"
                  ],
                  "additionalProperty": [
                    {
                      "@type": "PropertyValue",
                      "name": "Material",
                      "value": "Steel"
                    },
                    {
                      "@type": "PropertyValue",
                      "name": "Sizes Available",
                      "value": "1.0mm - 3.0mm"
                    },
                    {
                      "@type": "PropertyValue",
                      "name": "Hardness",
                      "value": "45 - 65 HRC"
                    }
                  ],
               
                }
                
                  `}
            </script>
      </Helmet>
      <Header heading={"Applications"} />
      <div className="application-intro">
        <p><strong>Discover the Versatility of Our Steel Shot Products</strong> <br />
          At Rotocast, we take pride in providing high-performance steel shots that find application across various industries. Our steel shots are engineered to meet the demanding needs of surface preparation, shot peening, and beyond. Explore the diverse applications where our steel shots excel:
        </p>
        <div className="applications">
          {applicationData.map((application, index) => (
            <Application key={index} {...application} />
          ))}
        </div>
        <p>Discover the difference that Rotocast Steel Shots can make in your industry. Contact us to discuss your specific needs and explore the possibilities of our high-quality steel shot products.
        </p>
      </div>
    </div>
  )
}

export default Applications