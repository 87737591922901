import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';

const Product10 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-70 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-70.2057fb4e018da80b0cd3.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-70</title>
    <meta name="description" content={`Steel Shot, Size S-70 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-70.2057fb4e018da80b0cd3.jpg' />
  </Helmet>

    <Header heading="Steel Shot, Size S-70" />

    <div className="productdetail">

        <img src={product10} alt="Steel Shots S-70" title="Steel Shots S-70" width="auto" height="auto"/>
        <p>Steel shot size S-70 refers to a specific classification of steel shots used in various industrial applications, particularly in the field of abrasive blasting. The “S-70” designation indicates the size of the individual steel particles, with a nominal diameter falling within a specific range.These steel shots are known for their durability and hardness, making them effective in processes like shot peening, surface preparation, and cleaning of metal surfaces. The S-70 size is often chosen based on the desired level of surface roughness and the specific requirements of the application. With its consistent size and hardness, S-70 steel shots contribute to achieving uniform and effective results in abrasive blasting operations, making it a preferred choice in industries where surface treatment is crucial for product quality and performance.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%
          Silicon 0.45-1.50%
          Manganese 0.35-1.20%
          Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C)
          Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product10