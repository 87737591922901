import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';


const Product1 = () => {
  return (
    <>
    <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Steel Shots",
          "description": "Steel Shot, Size S-930 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
          "image": "https://steelshot.in/static/media/s-930.ecdecdb2a50c1bc4199a.jpg",
          "brand": {
              "@type": "Brand",
              "name": "Steel Shots"
          }
      }`
        }
      </script>
      <Helmet>
        <title>Rotocast - Steel Shot, Size S-930</title>
        <meta name="description" content={`Steel Shot, Size S-930 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

        <meta property="og:image" content='https://steelshot.in/static/media/s-930.ecdecdb2a50c1bc4199a.jpg' />
      </Helmet>
    <Header heading="Steel Shot, Size S-930" />

    <div className="productdetail">

        <img src={product1} alt="Steel Shot, Size S-930" title="Steel Shot, Size S-930" width="auto" height="auto"/>
        <p>Steel shot size S-930 is a high-quality abrasive media widely utilized in various industrial applications, particularly in the field of surface preparation and shot peening. With a nominal diameter of 0.93 millimeters, S-930 steel shots are designed to provide an optimal balance between durability and efficiency. This specific size is often preferred for applications where a fine, controlled surface finish is crucial. The S-930 steel shots exhibit superior hardness, ensuring prolonged life and consistent performance during abrasive blasting processes. Industries such as automotive, aerospace, and metal fabrication rely on S-930 steel shots to achieve precise and uniform surface treatments, ultimately enhancing the durability and performance of the treated materials. The stringent manufacturing standards associated with S-930 guarantee reliability and adherence to industry specifications, making it a preferred choice for professionals seeking consistent and effective abrasive media for their surface preparation needs.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%</p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product1