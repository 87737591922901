import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';

const Product2 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-780 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-780.838c2c22339ec29a05e5.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-780</title>
    <meta name="description" content={`Steel Shot, Size S-780 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-780.838c2c22339ec29a05e5.jpg' />
  </Helmet>

    <Header heading="Steel Shot, Size S-780" />

    <div className="productdetail">

        <img src={product2} alt="Steel Shot, Size S-930" title="Steel Shot, Size S-930" width="auto" height="auto"/>
        <p>Steel shot size S-780 refers to a specific classification within the range of steel shots used in various industrial applications, particularly in abrasive blasting processes. The S in S-780 denotes that it conforms to the SAE J827 specification, indicating a spherical shape. The number 780 corresponds to the average diameter of the steel shots in hundredths of an inch, specifically 0.078 inches or 2.0 millimeters. S-780 steel shots are characterized by their robustness and durability, making them suitable for demanding tasks such as surface preparation, cleaning, and shot peening in industries like shipbuilding, automotive, and metal fabrication. The size S-780 is often chosen for its ability to deliver effective and consistent results in removing rust, scale, and contaminants from various surfaces while contributing to achieving the desired surface finish.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%

          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product2