import React, { useState } from 'react';
import Header from './Header';
import '../Styles/contact.scss';
import '../Styles/mediaQuary.scss';

import { useForm, ValidationError } from '@formspree/react';
import { Helmet } from 'react-helmet';

function ContactForm() {
  const [state, handleSubmit] = useForm("mleqjlbl");
  if (state.succeeded) {
    return <p>Thanks for reaching out! We'll get back to you soon.</p>;
  }

  return (
    <form className='form' onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name</label>
        <input id="name" type="text" name="name" required />

        <label htmlFor="email">Email Address</label>
        <input id="email" type="email" name="email" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />

        <label htmlFor="location">Location</label>
        <input id="location" type="text" name="location" required />

        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" required />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>

      <button data-cursorpointer={true} type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}

const Concatcus = () => {
  return (
    <div className="concatcus">
    <Helmet>
    <title>Rotocast Steel Shot: Leading Steel Shot Manufacturer in India</title>
    <meta name="description" content="Premium steel shot. Quality, innovation, satisfaction. Surface prep, shot peening & more. Contact us today!" />
    <meta name="keywords" content="Rotocast steel shot, steel shot manufacturer" />
    <link rel='canonical' href='https://steelshot.in/contactus' />

    <meta property="og:title" content="Leading Steel Shot Manufacturer in India" />
    <meta property="og:site_name" content="Rotocast" />
    <meta property="og:url" content='https://steelshot.in/contactus' />
    <meta property="og:description" content="High-quality abrasive media for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale" />
    <meta property="og:type" content="product" />
    <meta property="og:image" content="https://rotocastgroup.com/wp-content/uploads/2023/09/Types_of_Steel_Shot-01.jpg" />

</Helmet>
      <Header heading={'Contact Us'} />
      <ContactForm />
    </div>
  );
};

export default Concatcus;
