import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';

const Product3 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-660 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-660.770611c8c2bcf537aecb.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-660</title>
    <meta name="description" content={`Steel Shot, Size S-660 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-660.770611c8c2bcf537aecb.jpg' />
  </Helmet>

    <Header heading="Steel Shot, Size S-660" />

    <div className="productdetail">

        <img src={product3} alt="Steel Shot, Size S-660" title="Steel Shot, Size S-660" width="auto" height="auto"/>
        <p>Steel shot size S-660 refers to a specific classification of steel shots used in abrasive blasting applications. The S in S-660 stands for steel, and the number 660 represents the average diameter of the individual steel shots in micrometers. In this context, S-660 is relatively large, indicating a robust and coarse abrasive particle size. This size is often employed in applications where a more aggressive and efficient abrasive action is required, such as in the removal of tough coatings or preparing surfaces for coating applications. The use of S-660 steel shots helps achieve desired surface profiles and cleanliness, making it a suitable choice for demanding industrial processes that require high-performance abrasive blasting.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product3