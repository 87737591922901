import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';

const Product4 = () => {
  return ( 
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-460 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-460.d654018ba26fae9af24f.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-460</title>
    <meta name="description" content={`Steel Shot, Size S-460 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-460.d654018ba26fae9af24f.jpg' />
  </Helmet>


    <Header heading="Steel Shot, Size S-460" />

    <div className="productdetail">

        <img src={product4} alt="Steel Shot, Size S-460" title="Steel Shot, Size S-460" width="auto" height="auto"/>
        <p>Steel shot size S-460 refers to a specific grade of abrasive material commonly used in industrial processes such as shot blasting and peening. The S designation indicates that it is a spherical shot, while the number 460 corresponds to the approximate diameter of the individual steel particles in micrometers. In the case of S-460, the average diameter falls within the range of 0.4 to 0.6 millimeters. This particular size is chosen based on the desired surface finish and intensity of the treatment. S-460 steel shots are known for their durability, high impact energy, and effective removal of rust, scale, and contaminants from metal surfaces. The uniformity in size ensures consistent results, making S-460 a popular choice in applications where precision and reliability are paramount.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus  &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product4