import React from 'react'
import Header from './Header';
import { useLocation } from 'react-router-dom';
import product1 from "../Assets/s-930.jpg";
import product2 from "../Assets/s-780.jpg";
import product3 from "../Assets/s-660.jpg";
import product4 from "../Assets/s-460.jpg";
import product5 from "../Assets/s-390.jpg";
import product6 from "../Assets/s-280.jpg";
import product7 from "../Assets/s-230.jpg";
import product8 from "../Assets/s-170.jpg";
import product9 from "../Assets/s-120.jpg";
import product10 from "../Assets/s-70.jpg";
import product11 from "../Assets/s-550.jpg";
import { Helmet } from 'react-helmet';


const Product6 = () => {
  return (
    <>
    <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Steel Shots",
      "description": "Steel Shot, Size S-280 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale",
      "image": "https://steelshot.in/static/media/s-280.143ec5cde87432f11186.jpg",
      "brand": {
          "@type": "Brand",
          "name": "Steel Shots"
      }
  }`
    }
  </script>
  <Helmet>
    <title>Rotocast - Steel Shot, Size S-280</title>
    <meta name="description" content={`Steel Shot, Size S-280 Used for surface preparation, cleaning, and shot peening in various industries. Conforms to SAE J827 spec, suitable for removing rust and scale`} />

    <meta property="og:image" content='https://steelshot.in/static/media/s-280.143ec5cde87432f11186.jpg' />
  </Helmet>


    <Header heading="Steel Shot, Size S-280" />

    <div className="productdetail">

        <img src={product6} alt="Steel Shot, Size S-280" title="Steel Shot, Size S-280" width="auto" height="auto"/>
        <p>Steel shot size S-280 refers to a specific classification of abrasive particles used in shot blasting applications. In the context of steel shots, the designation S-280 indicates the size of the individual particles. The S stands for steel, and the number 280 denotes the nominal diameter of the shot in hundredths of an inch. Therefore, S-280 steel shots have a nominal diameter of approximately 0.028 inches. These shots are commonly employed in industrial processes such as shot peening and abrasive blasting, where they play a crucial role in surface preparation, cleaning, and strengthening of materials. The S-280 size is selected based on the desired impact energy and surface coverage, making it suitable for applications requiring efficient and uniform abrasion for tasks like removing rust, scale, or old coatings from various surfaces.
        </p>
      </div>
      <div className="moreproductdetail">
        <div className="ChemicalComposition">
          <h3>Chemical Composition</h3>
          <p>Carbon 0.85-1.20%, Silicon 0.45-1.50%, Manganese 0.35-1.20%, Phosphorus &lt;0.05%, Sulphur &lt;0.05%
          </p>

        </div>
        <div className="Hardness">
          <h3>Hardness</h3>
          <p>40-50 HRc. (Rockwell C), Custom hardness on request
          </p>
        </div>
        <div className="Microstructure">
          <h3>Microstructure</h3>
          <p>Uniform martensite, tempered to a degree consistent with the hardness range, with fine, well distributed carbides, if any.
          </p>
        </div>
      </div>
    </>
  )
}

export default Product6